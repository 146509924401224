import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';


// reactstrap components
import {
    Button,
    Form,
    Input,
    Label,
    FormGroup,
    Alert,
  } from "reactstrap";

export default class SubscribeForm extends React.Component {
    state = {
        email: '',
        message: '',
        result: '',
        firstName: '',
        lastName: '',
        addr1: '',
        city: '',
        state: '',
        zip: '',
        checked: false,
        subAlertSuccess: false,
        subAlertError: false,
    };

    handleCheckboxChange = (event) => {
        this.setState({ checked: event.target.checked })
    };

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        const result = await addToMailchimp(this.state.email, {
            FNAME: this.state.firstName,
            LNAME: this.state.lastName,
            TMPADDR: `${this.state.addr1}, ${this.state.city}, ${this.state.state}, ${this.state.zip}`,
          });
        this.setState({ message: result.msg });
        this.setState({ result: result.result });

        if (this.state.result === 'success') {
            this.setState({ subAlertError: false });
            this.setState({ subAlertSuccess: true });
            this.setState({
                email: '',
                firstName: '',
                lastName: '',
                addr1: '',
                city: '',
                state: '',
                zip: '',
                checked: false,
            })
            setTimeout(() => {
                this.setState({ subAlertSuccess: false });
            }, 5000);
        } else {
            this.setState({ subAlertSuccess: false });
            this.setState({ subAlertError: true });
            // setTimeout(() => {
            //     this.setState({ subAlertError: false });
            // }, 3000);
        }
    };    
    
    render() {
        return (
            <>
                <Form method="POST" onSubmit={this.handleSubmit}>
                    <div className="form-row">
                        <FormGroup className="col-md-6">
                            <Label for="firstName">First Name</Label>
                            <Input
                                type="text"
                                id="firstName"
                                name="firstName"
                                placeholder="Jane"
                                value={this.state.firstName}
                                onChange={this.handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup className="col-md-6">
                            <Label for="lastName">Last Name</Label>
                            <Input
                                type="text"
                                id="lastName"
                                name="lastName"
                                placeholder="Doe"
                                value={this.state.lastName}
                                onChange={this.handleInputChange}
                            />
                        </FormGroup>
                    </div>
                    <FormGroup>
                        <Label for="addr1">Address</Label>
                        <Input
                            type="address"
                            id="addr1"
                            name="addr1"
                            placeholder="123 Main Street"
                            value={this.state.addr1}
                            onChange={this.handleInputChange}
                        />
                    </FormGroup>
                    <div className="form-row">
                        <FormGroup className="col-md-4">
                            <Label for="city">City</Label>
                            <Input
                                type="address"
                                id="city"
                                name="city"
                                placeholder="Elkhorn"
                                value={this.state.city}
                                onChange={this.handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup className="col-md-4">
                            <Label for="state">State</Label>
                            <Input
                                type="address"
                                id="state"
                                name="state"
                                placeholder="NE"
                                value={this.state.state}
                                onChange={this.handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup className="col-md-4">
                            <Label for="zip">Zip</Label>
                            <Input
                                type="address"
                                id="zip"
                                name="zip"
                                placeholder="68022"
                                value={this.state.zip}
                                onChange={this.handleInputChange}
                            />
                        </FormGroup>
                    </div>
                    <FormGroup>
                        <Label for="email">Email</Label>
                        <Input 
                            type="email"
                            id="email"
                            name="email"
                            placeholder="jane.doe@website.com"
                            value={this.state.email}
                            onChange={this.handleInputChange}
                        />
                    </FormGroup>
                    <FormGroup check>
                        <Label className="form-check-label">
                        <Input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            checked={this.state.checked}
                            onChange={this.handleCheckboxChange}
                        />
                        I'm not a robot
                        <span className="form-check-sign">
                            <span className="check"></span>
                        </span>
                        </Label>
                    </FormGroup>
                    <Alert color="success" isOpen={this.state.subAlertSuccess} >
                        Success: {this.state.message}
                    </Alert>
                    <Alert color="danger" isOpen={this.state.subAlertError} >
                        Error: {this.state.message}
                    </Alert>
                    <Button
                        type="submit"
                        color="primary"
                        disabled={!this.state.checked}
                    >Submit</Button>
                </Form>

            </>
        );
    }
}
