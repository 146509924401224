import * as React from "react"

import Layout from "../components/layout"

// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Input,
  Label,
  FormGroup,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardText
} from "reactstrap";

import OtherPageHeader from "../components/otherPageHeader.js";
import SubscribeForm from "../components/subscribeForm.js";

// icons
import NextdoorIcon from "../images/svg/nextdoor.svg";
import FacebookIcon from "../images/svg/facebook.svg";
import EmailIcon from "../images/svg/email.svg";


const ContactPage = () => (
  <Layout pageTitle="Contact">
    <OtherPageHeader />
    <div className="main">
      <div className="section section-light-blue">
        <Container>
          <Row>
            <Col>
              <h4 className="display-4">Contact Us!</h4>
              <p className="lead">Have a question? Have an idea?</p>
              <p>We encourage feedback and suggestions from our neighbors. Please feel free to reach out to us.</p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="section">
        <Container>
          <Row className="align-items-center">
            <Col md="6">
              <h3 className="text-primary">Board Contact</h3>
              <Row>
                <Col md="6">
                  <Card className="no-transition">
                    <CardHeader>President</CardHeader>
                      <CardBody>
                        <CardTitle>Barry DeJong</CardTitle>
                        <CardText>
                          2704 N 191st Street<br />
                          Elkhorn, NE 68022<br />
                          402-213-1010<br />
                          BDejong1015@gmail.com
                        </CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {/* <p className='lead'>President:</p>
              <p>Zac Hickle<br />
              19112 Corby St<br />
              Elkhorn, NE 68022<br />
              402-612-2836<br />
              zhickle@yahoo.com</p> */}
 
              <h3 className="text-primary">Send us an email directly</h3>
              <Button
                  href="mailto:elkhornhighlandridgehoa@gmail.com"
                  key="email"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="email"
                  className="btn-link"
              >
                <EmailIcon style={{ height: '22px', width: '22px', margin: '5px' }}/>
                elkhornhighlandridgehoa@gmail.com
              </Button>

              <h3 className="text-primary">Stay connected on social media</h3>
              <Button
                href="https://www.facebook.com/groups/201047716712224/"
                key="facebook"
                target="_blank"
                rel="noopener noreferrer"
                title="facebook"
                className="btn-link"
              >
                <FacebookIcon style={{ height: '22px', width: '22px', margin: '5px' }} />
                Facebook
              </Button>
              <Button
                  href="https://nextdoor.com/neighborhood/elkhornhighlandridge--elkhorn--ne/"
                  key="nexdtoor"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="nextdoor"
                  className="btn-link"
              >
                <NextdoorIcon style={{ height: '22px', width: '22px', margin: '5px' }}/>
                Nextdoor
              </Button>
            </Col>
            <Col md="6">
              <div class="alt-jumbo rounded px-3 px-sm-4 py-3 py-sm-5">
                <h3 className="text-primary text-center">Join the email list to stay up to date via email</h3>
                <p className="description text-center">Benefits include: Lower cost of communication, more accurate and up to date information, and more easily communicate with the board of directors!</p>
                <hr className="my-2 mx-5" />
                <SubscribeForm />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  </Layout>
)

export default ContactPage
